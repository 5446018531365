import React from 'react';
import './App.css'
import Body from './components/body/Body';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import QuestionsAndAnswers from './components/QuestionsAndAnswers.js/QuestionsAndAnswers';

const App = () => {

  return (
      <Router>
        <div class="App" id="app">
          <Switch>
          <Route path="/" exact component={Body}/>    
          <Route path="/questionsandanswers" exact component={QuestionsAndAnswers}/>   

            <Route  path="/" >
              <Redirect to="/" />
            </Route>
            
          </Switch>
        </div>
      </Router>
  );
}

export default App;
