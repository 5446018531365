import React from 'react';
import classes from './Categories.module.css';
import qualityImage from '../../static/images/justlines_love.jpg';
import fastShipping from '../../static/images/justlines_delivery.jpg';
import certificate from '../../static/images/justlines_certificate.jpg';


const Categories = () => {
    return (
    <>
    <div className={classes.divider_3}></div>
    <div className={classes['second-div']}>
        <h4>What Makes Us Different</h4>
            <div className={classes['flex-div']}>
            
                
                <div className={classes['item']}  data-aos="fade-right" >
                <img src={fastShipping} className={classes.image} id="delivery" alt="Fast Delivery" />
                    <h3 className={classes.title}> משלוחים מהירים </h3>
                    
                    <div className={classes.body}>
                        <p> just lines מספקים משלוחים זולים ומהירים אל כל רחבי הארץ באמצעות נקודות מסירה. המוצר יהיה מוכן לאיסוף עד 12 ימי עסקים.</p>
                    </div>
                </div>

                <div className={classes['item']}  data-aos="fade-up">
                   <img src={qualityImage} className={classes.image} alt="HandMade Design" />
                    <h3 className={classes.title}> עבודת יד </h3>
                    <div className={classes.body}>
                    <p>כל האיורים נעשים בעבודת יד, בסגנון ייחודי המשלב רק קווים והמון אהבה. just lines מאמינים בשיתוף הלקוח בתהליך עד לקבלת התוצאה המושלמת.</p>
                    </div>
                </div>

                <div className={classes['item']}  data-aos="fade-left">
                <img src={certificate} className={classes.image} alt="High Quality" />
                    <h3 className={classes.title}> מוצרים איכותיים </h3>
                    <div className={classes.body}>
                    <p>כל מוצרי just lines נבחרו בקפידה, תוך שימת דגש על איכות ללא פשרות. המסגרות הינן מסגרות אלומיניום מעוצבות בעבודת יד, בסגנון מודרני ונקי. </p>
                    </div>
                </div>

            </div>
            
    </div>
    <div className={classes.divider_3}></div>
    </>
    )
}

export default Categories;
