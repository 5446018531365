import React, { useEffect } from 'react';
import classes from './Question.module.css';
import Aos from 'aos';
import "aos/dist/aos.css";

const Question = (props) => {
    

    const iconClass = props.openOrNot  ? "fa fa-angle-up " : "fa fa-angle-down ";

    const changeIt = () => {
        // const theAnswer = document.getElementById("answer");
        // console.log(`${props.id} IS NOW OPEN`)
        props.myFunc(props.id)
        // console.log(props.openOrNot)
        // console.log(props.openOrNot)

        // if (seeTheAnswer){
        //     setSeeTheAnswer(false)
        // } else {
        //     setSeeTheAnswer(true)
   
        // }
    }


    useEffect( () => {
        Aos.init({duration: 2000});
    }, [])

    

    return (
        <div className={classes.item} onClick={changeIt}>
            <div className={classes.question}>
                <a> <i id="icon" class={iconClass}></i></a>
                <p className={classes['text_question']}>{props.question}</p>
            </div>
            {props.openOrNot ? <p id="answer" className={classes.answer}> {props.answer.map( (part) => <p> {part} </p> )} </p> : null} 
        </div>
    )
}

export default Question;
