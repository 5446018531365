import React, { useState, useEffect } from 'react';
import classes from './Size.module.css';
import border from '../../static/images/border.png';
import mail_format from '../../static/images/mail_format.jpg';
import Aos from 'aos';
import "aos/dist/aos.css";

const Sizes = () => {
    const prices = {
        small: '230₪',
        medium: '270₪',
        large: '300₪',
        mail: '170₪'
    }

    const realSizes = {
        small: '13cm X 18cm',
        medium: '20cm X 30cm',
        large: '30cm X 40cm',
        mail: 'PDF קובץ',
    }

    const [pressOnOneSize, setPressOnOneSize] = useState(false)
    const [width, setWidth] = useState('180px')
    const [height, setHeight] = useState('220px')
    const [selectedSize, setSelectedSize] = useState('Medium')
    const [price, setPrice] = useState(prices['medium'])
    const [theRealSizes, setTheRealSizes] = useState(realSizes['medium'])
    const [pressOnMailButton, setPressOnMailButton] = useState(false)

    const smallButton = document.getElementById('small')
    const mediumButton = document.getElementById('medium');
    const largeButton = document.getElementById('large')

    const myFunc = (id, id1, id2, id3) => {
        let currentButton = document.getElementById(`${id}`);
        let currentButton1 = document.getElementById(`${id1}`);
        let currentButton2 = document.getElementById(`${id2}`);
        let currentButton3 = document.getElementById(`${id3}`);

        currentButton.classList.add(classes.active)
        currentButton1.classList.remove(classes.active)
        currentButton2.classList.remove(classes.active)
        currentButton3.classList.remove(classes.active)
    }

    const myFuncForMail = (id, id1, id2, id3) => {
        let currentButton = document.getElementById(`${id}`);
        let currentButton1 = document.getElementById(`${id1}`);
        let currentButton2 = document.getElementById(`${id2}`);
        let currentButton3 = document.getElementById(`${id3}`);

        currentButton.classList.add(classes.active_for_mail)
        currentButton1.classList.remove(classes.active)
        currentButton2.classList.remove(classes.active)
        currentButton3.classList.remove(classes.active)
    }

    // useEffect( () => {
    //     // console.log('width or heiht has changed!')
        
    // }, [width, height])


    useEffect( () => {
        Aos.init({duration: 2000});
    }, [])

    const onSmallHandler = () => {
        const fontSizeImage = document.getElementById("the_real_size");
        const smallButton = document.getElementById('small')
        const mediumButton = document.getElementById('medium');
        const largeButton = document.getElementById('large');
        const mailButton = document.getElementById('mail');


        fontSizeImage.style.fontSize = "11px";

        // console.log('make it Smaller')
        setWidth('160px')
        setHeight('190px')
        setSelectedSize('Mail')
        myFunc('small', 'medium', 'large', 'mail')
        setPrice(prices['small'])
        setTheRealSizes(realSizes['small'])
        setPressOnOneSize(true)
        setPressOnMailButton(false)
    }

    const onMediumHandler = () => {
        const fontSizeImage = document.getElementById("the_real_size");
        const smallButton = document.getElementById('small')
        const mediumButton = document.getElementById('medium');
        const largeButton = document.getElementById('large')
        const mailButton = document.getElementById('mail');

        fontSizeImage.style.fontSize = "12px";

        // console.log('make it Medium')
        setWidth('180px')
        setHeight('220px')
        setSelectedSize('Medium')
        myFunc('medium', 'large', 'small', 'mail')
        setPrice(prices['medium'])
        setTheRealSizes(realSizes['medium'])
        // mediumButton.classList.add(classes.active)
        setPressOnOneSize(true)
        setPressOnMailButton(false)
        
    }

    const onLargeHandler = () => {
        const fontSizeImage = document.getElementById("the_real_size");
        const smallButton = document.getElementById('small')
        const mediumButton = document.getElementById('medium');
        const largeButton = document.getElementById('large')
        const mailButton = document.getElementById('mail');

        
        fontSizeImage.style.fontSize = "13px";

        // console.log('make it Larger')
        setWidth('200px')
        setHeight('240px')
        setSelectedSize('Large')
        myFunc('large', 'small', 'medium', 'mail')
        setPrice(prices['large'])
        setTheRealSizes(realSizes['large'])
        setPressOnOneSize(true)
        setPressOnMailButton(false)
    }

    const onMailHandler = () => {
        const fontSizeImage = document.getElementById("the_real_size");
        const smallButton = document.getElementById('small')
        const mediumButton = document.getElementById('medium');
        const largeButton = document.getElementById('large')
        const mailButton = document.getElementById('mail');

        fontSizeImage.style.fontSize = "12px";

        setPressOnMailButton(true)

        setWidth('180px')
        setHeight('220px')
        setSelectedSize('Large')
        myFunc('mail','large', 'small', 'medium')
        setPrice(prices['mail'])
        setTheRealSizes(realSizes['mail'])
        setPressOnOneSize(true)
        
    }

    return (
        <>
        <div className={classes['second-div']}  data-aos="fade-up"> 
            
            <div className={classes['inner-div']}>
                    <div className={classes.space}>
                        <h3 >Sizes & Prices</h3>  
                        <h5> <b> Just Lines </b> comes in  3 sizes</h5>
                    </div>
                    <div className={classes.buttons_div}>
                            <button id="small" className={classes.size} onClick={onSmallHandler}>S</button>
                            <button id="medium" className={`${classes.size} ${classes.active}`} onClick={onMediumHandler}>M</button>
                            <button id="large" className={classes.size} onClick={onLargeHandler}>L</button>
                            <button id="mail" className={classes.size} onClick={onMailHandler}><i class="fa fa-envelope"></i></button>
                    </div>
                    <div className={classes['absu-div']}>
                        <div className={classes.container}>
                            <img src={pressOnMailButton ? mail_format : border } className={classes.image} style={{width, height, border: '2px solid black'}}></img>
                            <div className={classes.overlay}>
                                  <div className={classes.text}><p className={classes['the_real_size']} id="the_real_size"> {theRealSizes} </p> <br></br> {price} </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes['new-div']}>
                        <p> המחיר כולל עד 3 דמויות, כל דמות נוספת בתוספת של 15 ש"ח</p>
                    </div>
                    <a className={classes['button_order']} href="https://wa.me/message/5RSEKMR2BBEYJ1">!אני רוצה להזמין </a> 
            </div>


            


                {/* <div className={classes['absu-div']}>
                    <img src={image1} style={{width, height, border: '2px solid black'}}></img>
                </div> */}
            

        </div>
        
        
        
        </>
    
    )
}

export default Sizes;
