import React, { useEffect } from 'react';
import classes from './About.module.css';
import Aos from 'aos';
import "aos/dist/aos.css";

const About = () => {

  useEffect( () => {
    Aos.init({duration: 2000});
}, [])

    return (
      <>
      <div className={classes['body']} id="ABOUT">
        <div className={classes['about-section']}>
          <div className={classes['inner-container']}>
            <h1>ABOUT US</h1>
            <p className={classes.text}>
            שחור על גבי לבן, בפשטות וברכות הקווים שלנו מספרים סיפור, מעלים זיכרון ומייצרים רגש. <br />
            בלי מילים, בלי רעשי רקע ובלי פרטים מיותרים - הם מצליחים  להעביר את המסר ולפגוע בול בנקודה. <br/>
            <b> Just Lines </b>  נולד מתוך מטרה לשמח ולרגש.
            אתם נותנים לנו את הכבוד לספר את הסיפור שלכם ואנחנו מתחייבים לשים את הלב בכל קו וקו. <br/>
            מזמינים אתכם להתרגש יחד איתנו ממתנה שעשויה לא רק מקווים, אלא גם מהמון אהבה.
              
            </p>

          </div>
       </div>
      </div>


      <div className={classes.divider}></div>
      </>
    );
}

export default About;
