import React, { Fragment } from 'react';
import classes from './Header.module.css';

const Header = () => {
    return (
        <Fragment>
        <div className={classes.header}> 
            <h1>It's All About Lines.</h1>
            <p>For the ones who see through the lines</p>
            <a className={classes['button_learn_more']} href="#SIZES" >הזמן עכשיו</a>
        </div>      
        </Fragment>
    )
}

export default Header