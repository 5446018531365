import React, { Component, useEffect } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import image1 from '../../static/images/new_image_1.jpg'
import image2 from '../../static/images/new_image_2.jpg'
import image3 from '../../static/images/new_image_3.jpg'
import image4 from '../../static/images/new_image_4.jpg'
import image5 from '../../static/images/new_image_5.jpg'
import { ClassNames } from '@emotion/react';
import classes from './SlideImage.module.css';
import './carousel.min.css';

import Aos from 'aos';
import "aos/dist/aos.css";

const  SlideImage = () =>{
          
  useEffect( () => {
    Aos.init({duration: 2000});
}, [])

        return (
          <>
          <div className={classes.divider_3}></div>
          <div className={classes.main} data-aos="fade-up">
            <h4 className={classes.tit}>Other small magics....</h4>
            <Carousel autoPlay="true" infiniteLoop="true" interval="2000" showStatus="false" showThumbs="false" transitionTime="0.5s" thumbWidth="50px" centerMode="true" transitionTime="80s">  
                <div>
                    <img className={classes.image_car} src={image5} />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <img className={classes.image_car} src={image4} />
                    {/* <p className="legend">Legend 2</p> */}
                </div>
                <div>
                    <img className={classes.image_car} src={image3} />
                    {/* <p className="legend">Legend 3</p> */}
                </div>
                <div>
                    <img className={classes.image_car} src={image1} />
                    {/* <p className="legend">Legend 3</p> */}
                </div>
                <div>
                    <img className={classes.image_car} src={image2} />
                    {/* <p className="legend">Legend 3</p> */}
                </div>
            </Carousel>
          </div>
          <div className={classes.divider_2}></div>
          </>
        );
    
};


export default SlideImage

// Don't forget to include the css in your page

// Using webpack or parcel with a style loader
// import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';

// Using html tag:
// <link rel="stylesheet" href="<NODE_MODULES_FOLDER>/react-responsive-carousel/lib/styles/carousel.min.css"/>