import React, { useState, useEffect } from 'react';
import classes from './QuestionsAndAnswers.module.css'
import Question from '../question/Question';
import { Link } from 'react-router-dom';
import Aos from 'aos';
import "aos/dist/aos.css";


const QusetionsAnswers = [
    {
        id: 0,
        question: "?איך פועלת שיטת המשלוח",
        answer: [" Just Lines פועלת בשיטה של נקודות מסירה ממגוון נקודות ברחבי הארץ. ",
                "- בעת ביצוע ההזמנה, בחרו את נקודת האיסוף הקרובה אליכם ותאמו הגעה. ",
                "- המוצר יהיה מוכן לאיסוף עצמי עד 12 ימי עסקים מיום ההזמנה.",
                "- איסוף חינם מקריית שמונה ותל אביב .",
                "- איסוף ממגוון איזורים נוספים בעלות 20₪ בלבד.",
                "- במידה וההזמנה עבור מועד מיוחד (חג/יום הולדת) ניתן לפנות בפרטי.",
                

            ],
        open: false
                
    },
    {
        id: 1,
        question: "?מהי מדיניות ההחזרות/החלפות",
        answer: ["מאחר והמוצרים שלנו מותאמים אישית לכל לקוח, לא ניתן להחזירם אלא במקרה של מוצר פגום.",
                "- איורים בהזמנה אישית לא ניתנים לביטול או החזרה אחרי שהציור נעשה. ", 
                "- ניתן לבטל הזמנת איור כל עוד לא התחילה עבודת האיור."],
        open: false
    },
    {
        id: 2,
        question: "?מה כולל התהליך",
        answer: ["- בשלב הראשון כל שעליכם לעשות הוא לבחור תמונה, כיתוב, גודל וצבע מסגרת .", 
                "- מספר ימים מרגע ביצוע ההזמנה, תישלח סקיצה של האיור לאישורכם. רק לאחר קבלת האישור האיור ישלח להדפסה.", 
                "- האיור יודפס, ימוסגר וישלח לנקודת האיסוף."],
        open: false
    },
    {
        id: 3,
        question: "?האם ניתן להוסיף/להחסיר דמויות מהתמונה",
        answer: ["בהחלט כן, ישנה אפשרות לחבר כמה דמויות לכדי תמונה אחת!",
                " אז גם אם אין לכם את הפריים המושלם, Just Lines כאן כדי ליצור אותו.",
                "-   יש להדגיש כי המחירים כוללים איור של עד חמש דמויות, כל דמות נוספת בעלות של  15₪ נוספים. " 
                 ],
        open: false
    }
]




const QuestionsAndAnswers = () => {

    const [list, setList] = useState(QusetionsAnswers)

    useEffect( () => {
        Aos.init({duration: 2000});
    }, [])


    const changeItNow = (id) => {
        // console.log(QusetionsAnswers[id].open)
        // let newList = QusetionsAnswers[id].open = !QusetionsAnswers[id].open
        // console.log(id)

        let instance = list.map(item => {
            if (item.id === id){
                return {...item, open: !item.open}
            } else {
                return {...item, open: false};
            }
        })
        setList(instance)
        // console.log(list)
        
     
    }


    return (
        <>
        {/* <NavBar item={myItem} func={myFunc} whatsappAnimation={getWhatsappAnimation} /> */}
        
        <div className={classes.general} data-aos="fade">
         <h6 style={{textAlign: 'center', color: 'white'}}>Common Questions and Answers</h6>
            {list.map( (questionAndAnswer) => {
                return <div> <Question  question={questionAndAnswer.question} answer={questionAndAnswer.answer} id={questionAndAnswer.id} openOrNot={questionAndAnswer.open} myFunc={changeItNow} /> </div>
            })}
            <div className={classes.button_div}>
                <Link to="/"  >
                    <button className={classes.go_back}> חזור לאתר </button>
                </Link>
            </div>
        </div>
        </>
        
    )
}

export default QuestionsAndAnswers;
