import React from 'react';
import './WhatsappIcon.css';


const WhatsappIcon = (props) => {
    // console.log(props.show)
    // console.log(props.playAnimation)
    
    let class1 = props.show ? "float display-none" : "float"
    let class2 = props.show ? "float-2 display-none" : "float-2"
    let animation1 = props.playAnimation ? "float ani" : "float"
    let animation2 = props.playAnimation ? "float-2 ani" : "float"
    return (
        <>
        <div>
            <a class={class1} id="whatsapp-icon" target="_blank" href="https://wa.me/message/5RSEKMR2BBEYJ1">
                <i class="fa fa-whatsapp my-float"></i> 
                 
            </a> 
        </div>
        <div>
            <a class={class2} id="instagram-icon" href="https://www.instagram.com/justlines.il/" target="_blank"> 
                <i class="fa fa-instagram my-float" aria-hidden="true"></i>
            </a>
        </div>
        </>
    )
}

export default WhatsappIcon;
