import React, { useEffect } from 'react';
import './Images.css';
import after from '../../static/images/after.jpg';
import after3 from '../../static/images/after3.jpg';
import after10 from '../../static/images/after10.jpg';    
import after12 from '../../static/images/after12.jpg';    
import after15 from '../../static/images/after15.jpg';    
import Aos from 'aos';
import "aos/dist/aos.css";



const Images = () => {
  
  

  // let width = window.screen.width;

  useEffect( () => {
    Aos.init({duration: 2000});
}, [])





  const showBackgroundImage1 = () => {
    const firstOver = document.getElementById("over1");
    firstOver.classList.toggle("overlay4")
    // console.log('Clicked!')
  }

  const showBackgroundImage2 = () => {
    const secondOver = document.getElementById("over2");
    secondOver.classList.toggle("overlay5")
    // console.log('Clicked!')
  }

  const showBackgroundImage3 = () => {
    const thirdOver = document.getElementById("over3");
    thirdOver.classList.toggle("overlay6")
    // console.log('6!')
  }

  const showBackgroundImage4 = () => {
    const fourthOver = document.getElementById("over4");
    fourthOver.classList.toggle("overlay7")
    // console.log('Clicked!')
  }

  const showBackgroundImage5 = () => {
    const fifthOver = document.getElementById("over5");
    fifthOver.classList.toggle("overlay8")
    // console.log('Clicked!')
  }

  

    return (
        <>
        <div class="main-conatiner"  data-aos="fade-up">
            
            <h4>Examples</h4>
            <h5> <b> Tap </b> the images to see the magic </h5>

        <main class="container">

          <div class="item-4" onClick={showBackgroundImage1} >
            <img class="img1" src={after15} alt=""  />
            <div id="over1">
                <div class="text">  </div>
            </div>
          </div>

          <div class="item-5" onClick={showBackgroundImage2}>
            <img class="img" src={after} alt=""/>
            <div id="over2">
                <div class="text">  </div>
            </div>
          </div>

          <div class="item-6" onClick={showBackgroundImage3}>
            <img class="img" src={after3} alt=""/>
            <div id="over3">
                <div class="text">  </div>
            </div>
          </div>

          <div class="item-7" onClick={showBackgroundImage4}>
            <img class="img" src={after10} alt=""/>
            <div id="over4">
                <div class="text">  </div>
            </div>
          </div>

          <div class="item-8" onClick={showBackgroundImage5}>
            <img class="img2" src={after12} alt=""/>
            <div id="over5">
                <div class="text">  </div>
            </div>
          </div>

        </main>
            <div class="divider"></div>
        </div>
        </>
    )
}

export default Images;

