import React from 'react';
import classes from './Visualize.module.css';

const Visualize = () => {
  return (
    <>
        <div className={classes.divider_3}></div>
            <div className={classes.main_visual_div}>
                <div className={classes.main_conatiner}>
                    <h4>Try It Yourself</h4>
                    <h5 className={classes.responsive_visual_title} style={{ direction: 'rtl', padding: '10px 0px 0px 0px' }}> לא בטוחים באיזה גודל לבחור? רוצים לבדוק לאן ה-JL שלכם הכי יתאים? נסו בעצמכם! </h5>
                </div>
                <div style={{ margin: '50px' }}>
                    <model-viewer
                        alt="Just Lines on the wall"
                        src="https://cdn.glitch.global/1a95cbf1-0334-47e7-80b8-70f1cadb6a4a/WhatsApp%20Image%202023-05-23%20at%2019.22.57.glb?v=1684859539682"
                        ar-modes="webxr scene-viewer"
                        ar
                        ios-src="https://cdn.glitch.global/1a95cbf1-0334-47e7-80b8-70f1cadb6a4a/WhatsApp%20Image%202023-05-23%20at%2019.22.57%20(1).usdz?v=1684859595120"
                        shadow-intensity="1"
                        camera-controls ar-placement="wall"
                        touch-action="pan-y"
                    >
                    </model-viewer>
                </div>
     
            </div>
        <div className={classes.divider_3}></div>
    </>
  )
}

export default Visualize;