import React from 'react';
import classes from './Footer.module.css';
import { Link } from 'react-router-dom';


const Footer = () => {
    const navStyle = {
        color: 'white',
        textDecoration: 'none',
        cursor: 'pointer',
        marginTop: '10px'
        
    }



    return (
        <div className={classes['footer']}>
            <div className={classes.theDiv}>
                {/* <a onClick={myPrivateFunc}  style={{cursor: 'pointer'}}>
                    לשאלות ותשובות 
                </a> */}


                {/* <a href="/questionsandanswers"> לשאלות ותשובות </a> */}
                <Link style={navStyle} to="/questionsandanswers">
                    <p className={classes['button_q_a_a']}>לשאלות ותשובות</p>
                </Link>

                <h3 className={classes.bold}>לפרטים נוספים</h3>


                
                <div className={classes.phone}>
                    <span><a href="tel:0543911192" className={classes['a_href']}>0543911192</a> </span>
                    <i class="fa fa-phone"></i>
                    
                </div>
                <div className={classes.phone}>
                    <a href="mailto:maayanlevi2001@gmail.com" className={classes['a_href']}>maayanlevi2001@gmail.com</a>
                    <i class="fa fa-at"></i>
                </div>

                <p className={classes.author}>@copyright Itay Gonen | 2021</p>
            </div>

        </div>
    )
}

export default Footer;
