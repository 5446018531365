import React,{ useState, useEffect } from 'react';
import './NavBar.css';
import logo from '../../static/images/justlines_logo.jpg';



const NavBar = (props) => {
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);


        return () => {
            window.removeEventListener("scroll", handleScroll);
            };
        }, []);

	
    const [menuOpen, setMenuOpen] = useState(false);
    const [whatsappAnimationOn, setWhatsappAnimationOn] = useState("123");
    
    useEffect(() => {
        props.func(menuOpen)
    }, [menuOpen])

    useEffect(() => {
        // console.log(window.scrollY)
    }, [window.scrollY])
    
    // if(document.getElementById('button').clicked == true)
    // {
    //    alert("button was clicked");
    // }



    const onClickMenuHandler = () => {
        const menuBtn = document.querySelector('.menu-btn');
        const navLinks = document.getElementById('nav_bar_links');
        const button1 = document.getElementById("button1");
        const hamburger = document.getElementById("hamburger");
        
 
        
        
        // console.log(document.styleSheets.over)
        navLinks.classList.toggle("open");
        // document.querySelectorAll('.nav-links li').forEach(link => {
		// 	link.classList.add("fade")
		// })
        
        if(!menuOpen) {

            menuBtn.classList.add('open');
            // console.log(button1)
            document.querySelectorAll('.nav-links li').forEach(link => {
                link.classList.add("fade")
            })
            hamburger.classList.remove("hamburger")
            hamburger.classList.add("hamburger_after_change")

            setMenuOpen(true)
        } else {
            document.querySelectorAll('.nav-links li').forEach(link => {
                link.classList.remove("fade")
            })
         menuBtn.classList.remove('open');
         hamburger.classList.remove("hamburger_after_change")
         hamburger.classList.add("hamburger")
        
         setMenuOpen(false)
       }
    }

    
    const examplesClickHandler = () => {
        let navLinks = document.querySelector('.nav-links');
        let menuBtn = document.querySelector('.menu-btn');
        let hamburger = document.getElementById("hamburger");
        document.querySelectorAll('.nav-links li').forEach(link => {
            link.classList.remove("fade")
        })
        hamburger.classList.remove("hamburger_after_change")
        hamburger.classList.add("hamburger")
        navLinks.classList.remove('open');
        menuBtn.classList.remove('open');
        setMenuOpen(false)
        // console.log('Clicked!')
    }

    // const menuBtn = document.querySelector('.menu-btn');
	// let menuOpen = false;
	// document.querySelector('.menu-btn').addEventListener('click', () => {
  	// if(!menuOpen) {
   	// 	menuBtn.classList.add('open');
    // 	menuOpen = true;
  	// } else {
    // 	menuBtn.classList.remove('open');
    // 	menuOpen = false;
  	// }
	// });


    const onClickContactUsButton = () => {
        const whatsappIcon = document.getElementById("whatsapp-icon");
        const instagramIcon = document.getElementById("instagram-icon");
        whatsappIcon.classList.add("ani")
        instagramIcon.classList.add("ani")
        setTimeout( () => {
            whatsappIcon.classList.remove("ani")
            instagramIcon.classList.remove("ani")
        }, 2000)
        // console.log(whatsappIcon)
        // console.log(instagramIcon)
        // setWhatsappAnimationOn(!whatsappAnimationOn)
        // console.log("Play animation!")
        props.whatsappAnimation(whatsappAnimationOn)
    }


    // const onClickHandler = () => {
    //     let navLinks = document.getElementById('nav_bar_links');
    //     navLinks.classList.toggle("open");
    //     // navLinks.classList.toggle("open")

    //     document.querySelectorAll('.nav-links li').forEach(link => {
	// 		link.classList.toggle("fade")
	// 	})
    // }

    // document.querySelector('.hamburger').addEventListener("click", () => {
	// 	document.querySelector('.nav-links').classList.toggle("open");
	// 	document.querySelectorAll('.nav-links li').forEach(link => {
	// 		link.classList.toggle("fade")
	// 	})
	// })

    return (
    <>
    <nav class={scrollPosition > 250 ? "shadow" : null}>
        <div class="menu-btn hamburger" id="hamburger" onClick={onClickMenuHandler}>
            <div class="menu-btn__burger"></div>
        </div>

		<div class="brand_title"> <img src={logo} class={scrollPosition > 150 ? "logo_image_greater" : "logo_image_regular"}  /> </div>
		    <ul class="nav-links" id="nav_bar_links"> 
		    	    <li><a  href="#EXAMPLES" onClick={examplesClickHandler}>EXAMPLES</a></li>
                    <li><a  href="#SIZES" onClick={examplesClickHandler} >SIZES & PRICES</a></li>
		    	    <li><a  href="#ABOUT" onClick={examplesClickHandler} >ABOUT</a></li>
		    </ul>
        <div class="brand_button"><button class="contact-button" onClick={onClickContactUsButton}>CONTACT US</button></div>
	</nav>

    </>
    )
}

export default NavBar;
