import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import './Advert.css';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),

  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {/* <CloseIcon style={{marginLeft: '10% !important'}} /> */}
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs() {
  
  const [open, setOpen] = React.useState(false);

  React.useEffect( () => {
    setTimeout(function(){ setOpen(true)}, 3000);
  }, [])


  const handleClose = () => {
    setOpen(false);
  };

  const closeAdHandler = () => {
      setOpen(false)
  }

  return (
      <>
    <div>
      <BootstrapDialog
        // data-aos="fade"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} style={{direction: 'rtl', textAlign: 'center'}}>
        <span style={{fontSize: '15px'}}> לקוחות יקרים שימו <i class="fa fa-heart heart_color"></i> </span> <br/> <b> 30% </b>   הנחה על התמונה השנייה בהזמנת 2 תמונות  <b> זהות </b>
        </BootstrapDialogTitle>
        {/* <DialogContent dividers> */}

          {/* <Typography gutterBottom style={{direction: 'rtl', textAlign: 'center'}}>
          לקוחות יקרים שימו לב, 
          
          </Typography> */}


          
        {/* </DialogContent> */}

        <div className="div_button_close_ad">
            <button class="contact-button-1" onClick={closeAdHandler}> הבנתי </button>
         </div>


    
      </BootstrapDialog>
    </div>
    </>
  );
}