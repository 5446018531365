import React, { useState } from 'react';
import NavBar from '../navbar/NavBar';
import Header from '../header/Header';
import Categories from '../categories/Categories';
import Size from '../sizesTry/Size';
import Images from '../grid-images/Images';
import About from '../about/About';
import WhatsappIcon from '../whatsapp/WhatsappIcon';
import Footer from '../footer/Footer';
import SlideImage from '../sliderImage/SlideImage';
import Advert from '../advert/Advert';
import Visualize from '../Visualize/Visualize';


const Body = () => {
    const [saved, setSaved] = useState("")
    const [playWhatsappAnimation, setPlayWhatsappAnimation] = useState(true)
    const myItem = "Itay";
    // let openOrNot = "";
    // console.log(openOrNot)
    const myFunc = (getItem) => {
      setSaved(getItem)
    }
  
  
    const getWhatsappAnimation = (getValue) => {
     setPlayWhatsappAnimation(true)
      // console.log(playWhatsappAnimation)
    }
  
    // console.log(playWhatsappAnimation)

    return (
        <div>
            <Advert />
            {/* <TryNav /> */}
            <NavBar item={myItem} func={myFunc} whatsappAnimation={getWhatsappAnimation} />
            {/* <Advert /> */}
            <Header />

            <div id="EXAMPLES" style={{scrollMargin: '500px 0 0 50px !important'}}>
                <div>
                    <Images />
                </div>
            </div>

            <div id="SIZES" style={{scrollMargin: '100px'}}>
                <Size />
            </div>

            <Visualize />

            <SlideImage/>
            
            <Categories/>
            <About />
            <WhatsappIcon show={saved} playAnimation={playWhatsappAnimation} />

            <Footer />
        </div>
    )
}

export default Body;


